// Dependencies
import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
// Hooks
import useUpdateHeader from "../hooks/useUpdateHeader"
// Components
import Layout from "../components/Layout"
import RichText from "../components/RichText"
import SplashImage from "../components/SplashImage"
import { RelatedProjects, RelatedJournals } from "../components/Related"

const ProjectTitle = ({ title, summary, hasHeroImage }) => {

	// UX
	const [ref, inView] = useUpdateHeader(hasHeroImage ? 'var(--white)' : 'var(--black)', false)

	return (
		<div className="project-title guttersx4 outerx2" ref={ref}>
			<h4 className="color--highlight">{title}</h4>
			<h1>{summary?.copy}</h1>
		</div>
	)
}

const ProjectDetails = ({ details }) => {

	return (
		<ul className="project-details guttersx4 outerx2 mbx4 p3">
			{details.map((item, index) => (
				<li key={`${item.id}${index}`}>
					<div className="project-detail-label">{item.label}</div>
					<div className="project-detail-content has-links"><RichText content={item.content} /></div>
				</li>
			))}
		</ul>
	)
}

const ProjectTemplate = ({ data }) => {

	// Content
	const { 
		title, 
		summary, 
		metaDescription,
		heroImage, 
		shareImage,
		content, 
		details, 
		relatedProjects, 
		relatedJournals 
	} = data.contentfulProject

  return (
    <Layout 
			headerTitle="Back to projects" 
			headerLink="/projects"
			hideLogo 
			theme={{
				background: 'var(--yellow-bg)',
				highlight: 'var(--yellow)'
			}}
			title={`${title} | AFK`}
			description={metaDescription}
			shareImage={shareImage}
		>
			<div className={`project-view ${!heroImage ? 'header-offset' : ''}`}>
				{/* Hero */}
				{heroImage && <SplashImage className="project-hero" visuals={[heroImage]} showLogo />}
				
				{/* Title */}
				{title && <ProjectTitle title={title} summary={summary} hasHeroImage={heroImage ? true : false} />}

				{/* Content */}
				{content && (
					<article className="p4 innerx4 has-indents has-links">
						<RichText content={content} />
					</article>
				)}
				
				{/* Details */}
				{details && <ProjectDetails details={details} />}

				{/* Related projects */}
				{relatedProjects && (
					<RelatedProjects projects={relatedProjects} />
				)}

				{/* Related Journals */}
				{relatedJournals && (
					<RelatedJournals journals={relatedJournals} />
				)}
			</div>
    </Layout>
  )
}

ProjectTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default ProjectTemplate

export const ProjectTemplatePageQuery = graphql`
  query ProjectTemplateQuery($slug: String!) {
    contentfulProject(slug: {eq: $slug}) {
			title
			slug
			metaDescription {
				text: metaDescription
			}
			summary {
				copy: summary
			}
			heroImage {
				...SplashImageFragment
			}
			shareImage: thumbnail {
				gatsbyImageData(
					layout: CONSTRAINED
					width: 640
				)
			}
			type {
				slug
			}
			form {
				slug
			}
			location {
				slug
			}
			content {
				raw
				references {
					... on Node {
						... on ContentfulGallery {
							...ContentfulGalleryFragment
						}
						... on ContentfulAsset {
							__typename # isRequired
							contentful_id # isRequired
							title
							description
							file {
								contentType
								url
							}
							image: gatsbyImageData(
								layout: FULL_WIDTH
								# formats: JPG
								quality: 80
							)
						}
						... on ContentfulQuote {
							...ContentfulQuoteFragment
						}
						... on ContentfulVideo {
							...ContentfulVideoFragment
						}
					}
				}
			}
			## Project > Detail
			details {
				id
				label
				content {
					raw
					references {
						... on ContentfulPerson {
							__typename
							contentful_id
							slug
						}
					}
				}
			}
			## Related content
			relatedProjects {
				...ProjectThumbnailFragment
			}
			relatedJournals {
				...JournalLinkFragment
			}
    }
  }
`

